import React, { useState, useEffect } from 'react';
import { MdDone } from 'react-icons/md';
import { Form, Input } from 'unform';
import { toast } from 'react-toastify';
// import { useSelector } from 'react-redux';
import { Modal, Select } from 'antd';
import * as Yup from 'yup';

import { Container, Header, Content, WrapperInput } from './styles';
import Button from '../../../components/Button';
import api from '../../../services/api'
import history from '../../../services/history'

const { Option } = Select;

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Nome é obrigatório'),
  email: Yup.string().email('E-mail inválido')
    .required('E-mail é obrigatório'),
});

export default function CreateUsers() {
  // const user = useSelector(state => state.user.user)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [, setChurch] = useState([])
  const [teams, setTeams] = useState([])
  const [team_id, setTeamId] = useState('')

  useEffect(() => {
    // SOLUÇÃO INICIAL PARA MUDAR COR DA BORDA DO SELECT
    document.getElementsByClassName("ant-select-selection")[0].style.border = '1px solid #EEE'

    async function getChurchs() {
      try {
        const response = await api.get('church')
        if (response.status === 200) {
          const churchs = response.data
          setChurch(churchs)
        }
      } catch (err) {
        console.log(err)
      }
    }

    async function loadTeams() {
      try {
        const response = await api.get('team')
        if (response.status === 200) {
          console.log(response.data)
          setTeams(response.data)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getChurchs()
    loadTeams()
  }, [])

  function info(user) {
    Modal.success({
      title: 'Usuário criado com sucesso, anote as informações',
      content: (
        <div>
          <p>email: {user.user.email}</p>
          <p>senha provisória: {user.generatedPassword}</p>
        </div>
      ),
      onOk() {
        history.replace('/');
      },
    });
  }

  async function handleAddUser(data) {
    console.log(team_id)
    try {
      if (!team_id) {
        return Modal.error({
          title: 'Dados inválidos!',
          content: (
            <div>
              <p>Selecione uma equipe</p>
            </div>
          )
        });
      }
      const response = await api.post('/generate', {
        name,
        email,
        team_id,
        role_id: 3
      })

      if (response.status === 200) {
        info(response.data)
      }
    } catch (err) {
      console.log(err)
      toast.error(`Falha ao adicionar usuário, tente novamente.`)
    }
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
    setTeamId(value)
  }

  return (
    <Container>
      <Form onSubmit={handleAddUser} schema={schema}>
        <Header>
          <strong>Criar Usuário</strong>
          <Button typeButton="secundary" icon={<MdDone />} title="SALVAR" />
        </Header>

        <Content>
          <WrapperInput style={{ marginTop: 10 }}>
            <div>
              <strong>NOME</strong>
              <Input
                name="name"
                value={name}
                onChange={(value) => setName(value.target.value)}
                maxLength={24}
              />
            </div>
            <div>
              <strong>EMAIL</strong>
              <Input
                type="email"
                name="email"
                value={email}
                onChange={(value) => setEmail(value.target.value)}
                maxLength={40}
              />
            </div>
          </WrapperInput>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <strong>EQUIPE</strong>
            <Select
              mode="default"
              placeholder="Selecione a equipe"
              onChange={handleChange}
              style={{ marginTop: 5 }}
              size="large"
            >
              {
                teams.map(team => (
                  <Option key={team.id} value={team.id}>{team.name}</Option>
                ))
              }
            </Select>
          </div>

        </Content>
      </Form>
    </Container>
  );
}
