import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import { getChurchSuccess } from './actions';


export function* getChurchs() {
  try {
    const response = yield call(api.get, 'church');

    const churchs = response.data;

    // api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(getChurchSuccess(churchs));
  } catch (err) {
    console.log(err)
    toast.error('Falha ao trazer igrejas.')
    // yield put(signInFailure());
  }
}

export default all([
  takeLatest('@church/GET_ALL_CHURCHS', getChurchs),
]);
