import React from 'react';
import { Route, Redirect } from 'react-router-dom'

import DefaultLayout from '../pages/_layouts/default'
import AuthLayout from '../pages/_layouts/auth'

import { store } from '../store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const { signed } = store.getState().auth

  if (!signed && isPrivate) {
    return <Redirect to="/login" />
  }

  if (signed && rest.path === '/login') {
    return <Redirect to="/" />
  }

  const Layout = isPrivate ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
