import React, { useState, useEffect } from 'react';
import { MdDone } from 'react-icons/md';
import { Form, Input } from 'unform';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Modal, Select } from 'antd';
import * as Yup from 'yup';

import { Container, Header, Content, WrapperInput } from './styles';
import Button from '../../../components/Button';
import api from '../../../services/api'
import history from '../../../services/history'

const { Option } = Select;

const schema = Yup.object().shape({
  teamName: Yup.string()
    .required('Nome da equipe é obrigatório'),
});

export default function CreateTeam() {
  const user = useSelector(state => state.user.user)

  const [name, setName] = useState('')
  const [church_id, setChurchId] = useState('')
  const [churchs, setChurchs] = useState('')


  useEffect(() => {
    // SOLUÇÃO INICIAL PARA MUDAR COR DA BORDA DO SELECT
    document.getElementsByClassName("ant-select-selection")[0].style.border = '1px solid #EEE'
    console.log('USER ', user)

    async function getChurchs() {
      try {
        const response = await api.get('church')
        if (response.status === 200) {
          const churchs = response.data
          console.log('CHURCHS', churchs)
          setChurchs(churchs)
        }
      } catch (err) {
        console.log(err)
      }
    }

    getChurchs()
  }, [user])

  function modalInfo() {
    Modal.success({
      title: `Equipe cadastrada com sucesso!`,
      onOk() {
        history.replace('/');
      },
    });
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
    setChurchId(value)
  }

  async function handleAddTeam(data) {
    if (!church_id) {
      return Modal.error({
        title: 'Dados inválidos!',
        content: (
          <div>
            <p>Selecione uma igreja.</p>
          </div>
        )
      });
    }

    try {
      const response = await api.post('/team', {
        name,
        church_id,
      })

      console.log('REPONSE ', response)

      if (response.status === 200) {
        modalInfo()
      }
    } catch (err) {
      console.log(err)
      toast.error(`Falha ao adicionar usuário, tente novamente.`)
    }
  }


  return (
    <Container>
      <Form onSubmit={handleAddTeam} schema={schema}>
        <Header>
          <strong>Criar equipe</strong>
          <Button type="submit" icon={<MdDone style={{ marginRight: 6 }} />} title="SALVAR" />
        </Header>

        <Content>
          <WrapperInput style={{ marginTop: 10 }}>
            <div>
              <strong>NOME DA EQUIPE</strong>
              <Input
                name="teamName"
                value={name}
                onChange={(value) => setName(value.target.value)}
                maxLength={24}
              />
            </div>
          </WrapperInput>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <strong>IGREJA</strong>
            <Select
              mode="default"
              placeholder="Selecione a igreja"
              onChange={handleChange}
              style={{ marginTop: 5 }}
              size="large"
            >
              {
                churchs && churchs.map(church => (
                  <Option
                    key={church.id}
                    value={church.id}
                    title={church.description}
                  >
                    {church.name}
                  </Option>
                ))
              }
            </Select>
          </div>

        </Content>
      </Form>
    </Container>
  );
}
