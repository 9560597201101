import styled from 'styled-components';
import { primary } from '../../styles/colors';

export const Container = styled.div`
  a {
    font-size: 14px!important;
  }
`;

export const Content = styled.div`
  height: 64px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

  nav {
    display: flex;
    flex-grow: 1;
    align-items: center;

    img {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid #EEE;
    }

    ul {
      display: flex;
      align-items: center;
    }

    li {
      & + li {
        margin-left: 20px;
      }
    }

  a {
    color: ${primary};
    font-weight: bold;
  }

  a.active {
    color: #444444;
  }
}
`

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;

  strong {
    color: #CCC;
  }

  button {
    background: transparent;
    border: 0;
    font-size: 14px;
    line-height: 16px;
    color: #EEE;
  }
`;


