import React, { useState, useEffect } from 'react';
import { MdDone } from 'react-icons/md';
import { Form, Input } from 'unform';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Container, Header, Content, WrapperInput } from './styles';
import Button from '../../../components/Button';
import api from '../../../services/api'
import history from '../../../services/history'

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Nome é obrigatório'),
  church: Yup.string()
    .required('Igreja é obrigatório'),
});

export default function CreateRooms() {
  const user = useSelector(state => state.user.user)

  const [name, setName] = useState('')
  const [church, setChurch] = useState('')

  useEffect(() => {
    async function getChurchs(){
      try {
        const response = await api.get('church')
        if(response.status === 200){
          const churchs = response.data
          const churchUser = churchs.find(c => c.id !== user.team_id)
          setChurch(churchUser)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getChurchs()
  }, [user.team_id])


  async function handleAddRoom({name}) {
    try {
      const response = await api.post('room', {
        name,
        church_id: church.id
      })

      if (response.status === 201) {
        toast.error(`Sala adicionada com sucesso!`)
        history.replace('/');
      }
    } catch (err) {
      console.log(err)
      toast.error(`Falha ao adicionar sala, tente novamente.`)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleAddRoom} schema={schema}>
        <Header>
          <strong>Criar Sala</strong>
          <Button type="submit" icon={<MdDone />} title="SALVAR" typeButton="secundary" />
        </Header>

        <Content>
          <WrapperInput style={{ marginTop: 10 }}>
            <div>
              <strong>NOME</strong>
              <Input
                name="name"
                value={name}
                onChange={(value) => setName(value.target.value)}
                maxLength={18}
              />
            </div>
            <div>
              <strong>IGREJA</strong>
              <Input
                name="church"
                value={church && church.name}
                disabled
              />
            </div>
          </WrapperInput>

        </Content>
      </Form>
    </Container>
  );
}
