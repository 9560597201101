import styled from 'styled-components';
import { primary } from '../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  align-self: center;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    font-size: 24px;
    color: #FFFFFF;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 5px 5px 0px;

      input {
      background: #FFF;
      border: 1px solid #EEE;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 3px 0 10px;
      border-radius: 4px;
    }
    div {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  background: #FFF;
  padding: 20px;
  margin-top: 15px;
  border-radius: 4px;

  span {
    color: ${primary};
    font-weight: bold;
  }
`;
