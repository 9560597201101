import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button';
import { signOutRequest } from '../../store/modules/auth/actions';

import { Container } from './styles';
// import api from '../../services/api';

export default function Profile() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const church = useSelector(state => state.user.church);
  const team = useSelector(state => state.user.team);

  // const [password, setPassword] = React.useState('');
  // const [newPassword, setNewPassword] = React.useState('');
  // const [confirmNewPassword, setConfirmNewPassword] = React.useState('');

  function logout() {
    dispatch(signOutRequest())
  }

  // function updateUser() {

  // }

  return (
    <Container>
      <form>
        <h5><strong>{user.name}</strong></h5>

        <strong>E-mail</strong>
        <input
          name="email"
          type="email" placeholder="Seu endereço de email"
          disabled
          value={user.email}
        />

        <strong>Igreja</strong>
        <input
          name="church"
          placeholder="Igreja"
          disabled
          value={church.name}
        />

        <strong>Equipe</strong>
        <input
          name="team"
          placeholder="Equipe"
          disabled
          value={team.name}
        />

        <hr />

        {/* <input
          name="oldPassword"
          type="password"
          placeholder="Sua senha atual"
        />
        <input
          name="password"
          type="password"
          placeholder="Nova senha"
        />
        <input
          name="confirmPassword"
          type="password"
          placeholder="Confirmação da senha"
        />

        <Button typeButton="primary" type="submit" title="Atualizar perfil" /> */}
      </form>

      <Button
        typeButton="secundary"
        type="button"
        title="Sair"
        onClick={logout}
      />
    </Container>
  );
}
