// export const primary = '#351954'
export const primary = '#22202C'
export const secundary = '#FFFFFF'

export const black = '#000000'
export const dark = '#444444'
export const darkgray = '#888888'
export const lightgray = '#CCCCCC'

export const green = '#25802C'
export const red = '#DE3B3B'
export const darkRed = '#A7010D'

export const white = '#FFFFFF'

export const colors = {
  primary,
  secundary,
  black,
  dark,
  darkgray,
  lightgray,
  green,
  red,
  darkRed,
  white,
}
