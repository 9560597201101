import React, { useEffect, useState } from 'react';
import { Table as TableAnt, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import Button from '../../components/Button';
import api from '../../services/api';

import { Container, Header, Content } from './styles';
import { green, red } from '../../styles/colors';

export default function ManagerRequests() {
  const churchUser = useSelector(state => state.user.church)
  const token = useSelector(state => state.auth.token)
  const size = useWindowSize()

  const [requests, setRequests] = useState([])
  const [arrayRequestsSelected, setArrayRequestsSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingAcceptRequests, setLoadingAcceptRequests] = useState(false)

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setArrayRequestsSelected(selectedRowKeys)
      return selectedRowKeys;
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    async function loadMyRequests() {
      try {
        setLoading(true)
        const response = await api.get(`/request/${churchUser?.id}`)

        const requestsOnlyChurchUser = response.data?.filter(r => r.church_id === churchUser?.id)

        if (response.status === 200) {
          setRequests(requestsOnlyChurchUser.sort(function (a, b) {
            if (a.status > b.status) {
              return 1;
            }
            if (a.status < b.status) {
              return -1;
            }
            // a must be equal to b
            return 0;
          }))
          console.log(requestsOnlyChurchUser)
        }
        setLoading(false)
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    }

    loadMyRequests()
  }, [churchUser])

  async function handleBatchAcceptRequest() {
    try {
      setLoadingAcceptRequests(true)
      // console.log(api.defaults)
      const response = await api.post(`/request/batchAccept`, {request_ids: arrayRequestsSelected}, {
        headers: {
          Authorization: `Bearer ${token.token}`
        }
      })

      if (response.status === 200) {
        toast.success('Reservas aceitas!')
        const response = await api.get(`/request/${churchUser?.id}`)

        if (response.status === 200) {
          setRequests(response.data)
        }
      }
    } catch (err) {
      toast.error('Erro ao aceitar reservas!')
      console.log(err)
    } finally {
      setLoadingAcceptRequests(false);
      setArrayRequestsSelected([])
    }
  }

  async function handleAcceptRequest(id) {
    try {
      // console.log(api.defaults)
      const response = await api.post(`/request/accept/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token.token}`
        }
      })

      if (response.status === 200) {
        toast.success('Reserva aceita!')
        const response = await api.get(`/request/${churchUser?.id}`)

        if (response.status === 200) {
          setRequests(response.data)
        }
      }
      console.log(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  async function handleRejectRequest(id) {
    try {
      console.log(api.defaults)
      const response = await api.post(`/request/refuse/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token.token}`
        }
      })

      if (response.status === 200) {
        toast.error('Reserva não aceita!')
        const response = await api.get(`/request`)

        if (response.status === 200) {
          setRequests(response.data)
          console.log(response.data)
        }
      }
      console.log(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: 'Sala',
      dataIndex: 'room',
      render: (room, request) => (
        <span>{room && room.name} {request.is_recurring ? <Tag>SALA FIXA</Tag> : ''}</span>
      ),
    },
    {

      title: 'Descrição',
      dataIndex: 'reason',
    },
    {
      title: 'Horário',
      dataIndex: 'start_at',
      render: start_at => `${moment(start_at).format('DD/MM - HH:mm')}`
    },
    {
      title: 'Equipe',
      dataIndex: 'user',
      render: user => `${user?.team?.name}`
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status - b.status,
      render: (status, request) => (
        <>
          {status === 0 && (
            <>
              <button
                style={{ border: 'none', background: 'transparent', color: "#4D85EE" }}
                onClick={() => handleAcceptRequest(request.id)}
              >
                aceitar
              </button>
              <button
                style={{ border: 'none', background: 'transparent', color: red, marginLeft: 10 }}
                onClick={() => handleRejectRequest(request.id)}
              >
                rejeitar
              </button>
            </>
          )}
          {status === 1 && <span style={{ color: green }}>aceito</span>}
          {status === 2 && <span style={{ color: red }}>recusado</span>}

        </>
      )
    },
  ];

  return (
    <Container>
      <Header>
        <strong>Reservas</strong>
        {arrayRequestsSelected?.length > 0 && (
          <Button
            title="Aceitar selecionadas"
            onClick={handleBatchAcceptRequest}
            loading={loadingAcceptRequests}
            typeButton="secundary"
          />
        )}
      </Header>

      <Content size={size}>
        <TableAnt
          rowSelection={rowSelection}
          className="card-list-table"
          loading={loading}
          columns={columns}
          rowKey={record => record.id}
          dataSource={requests}
        />
      </Content>
      {/* {
        windowWidth > 980 && (
          <Content>
            <Table>
              <thead>
                <tr>
                  <th>SALA</th>
                  <th>MOTIVO</th>
                  <th>EQUIPE</th>
                  <th>HORÁRIO</th>
                  <th>STATUS</th>
                  <th style={{ textAlign: 'center' }}>#</th>
                </tr>
              </thead>
              <tbody>
                {requests && requests.map(request => (
                  <tr key={request.id}>
                    <td> {request.room && request.room.name} {request.is_recurring ? <span className="sala_fixa">SALA FIXA</span> : ''} </td>
                    <td> {request.reason} </td>
                    <td> {request.user && request.user?.team?.name} </td>
                    <td> {request && moment(request.start_at).format('DD/MM/YYYY HH:mm')} </td>
                    <td>
                      {request?.status === 0 && <span style={{ color: darkgray }}>pendente</span>}
                      {request?.status === 1 && <span style={{ color: green }}>aceito</span>}
                      {request?.status === 2 && <span style={{ color: red }}>recusado</span>}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {request && request.status === 0 &&
                        <>
                          <button
                            style={{ color: "#4D85EE" }}
                            onClick={() => handleAcceptRequest(request.id)}
                          >aceitar
                      </button>
                          <button
                            style={{ color: red, marginLeft: 10 }}
                            onClick={() => handleRejectRequest(request.id)}
                          >rejeitar</button>
                        </>
                      }
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </Table>
          </Content>
        )
      }

      {windowWidth <= 980 && (
        requests && requests.map(request => (
          <Card key={request.id} style={{ marginTop: 12 }} bodyStyle={{ padding: 10 }}>

            {request?.status === 0 && <Alert type="info" style={{ textAlign: 'center', fontWeight: 'bold' }} message="pendente" />}
            {request?.status === 1 && <Alert type="success" style={{ textAlign: 'center', fontWeight: 'bold' }} message="aceito" />}
            {request?.status === 2 && <Alert type="error" style={{ textAlign: 'center', fontWeight: 'bold' }} message="recusado" />}

            <p style={{ marginTop: 10 }}>{request?.room?.name} - {request && moment(request.start_at).format('DD/MM/YYYY HH:mm')}</p>
            <p>{`${request?.user?.name} - ${request?.user?.team?.name}`} - {request.reason}</p>
            <div style={{ marginTop: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {request && request.status === 0 &&
                <>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <button
                      style={{ color: "#4D85EE", background: "transparent", border: "none", width: '60%' }}
                      onClick={() => handleAcceptRequest(request.id)}
                    >
                      aceitar
                    </button>
                  </div>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <button
                      style={{ color: red, marginLeft: 10, background: "transparent", border: "none", width: '60%' }}
                      onClick={() => handleRejectRequest(request.id)}
                    >
                      rejeitar
                    </button>
                  </div>
                </>
              }
            </div>
          </Card>
        )))
      } */}

    </Container>
  );
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
