import styled from 'styled-components';
import { darken } from 'polished';
import { colors } from '../../styles/colors';

interface ButtonProps {
  typeButton: 'primary' | 'secundary'
}


export const Container = styled.button<ButtonProps>`
  background: ${props => colors[props.typeButton]};
  height: 36px;
  padding: 0 10px;
  border: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  transition: background 0.2s;

  &:hover {
    background: ${props => darken('0.04', colors[props.typeButton])};
  }
  div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    svg {
      color: ${colors.primary};
      font-size: 20px;
    }
    span {
      color:  ${props => props.typeButton === 'primary' ? colors.white : colors.primary};
      text-align: center;
      font-weight: bold;
      margin: 0;
    }
  }
`;
