import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { Container, Header, Table, Content } from './styles';
import Button from '../../../components/Button';
import history from '../../../services/history';
import api from '../../../services/api';

export default function ListTeams() {
  const userLogger = useSelector(state => state.user.user)
  const [teams, setTeams] = useState([])

  useEffect(() => {
    async function loadTeams() {
      try {
        const response = await api.get(`team`)
        console.log('EQUIPES ', response.data)

        if (response.status === 200) {
          setTeams(response.data)
        }
      } catch (err) {
        console.log(err)
      }
    }

    loadTeams()
  }, [])

  async function deleteUser(id) {
    try {
      await api.delete(`team/${id}`)

      toast.success('Usuário excluído com sucesso.')

      setTeams(teams.filter(r => r.id !== id));
    } catch (err) {
      toast.error('Erro ao excluir usuário.')
      console.log(err)
    }
  }

  return (
    <Container>
      <Header>
        <strong>Equipes</strong>
        <div>
          <Button
            icon={<MdAdd />}
            title="ADICIONAR"
            onClick={() => history.push('/manager/teams/create')}
            typeButton="secundary"
          />
        </div>
      </Header>

      <Content>
        <Table>
          <thead>
            <tr>
              <th>NOME</th>
              <th>IGREJA</th>
              <th style={{ textAlign: 'center' }}>#</th>
            </tr>
          </thead>
          <tbody>
            {teams && teams.map(team => (
              <tr key={team.id}>
                <td> {team.name} </td>
                <td> {team?.church_id === 1 ? 'Sede' : 'Ceilândia'} </td>
                <td style={{ textAlign: 'center' }}>
                  {team && team.id !== userLogger?.team_id &&
                    <MdDelete
                      size={16}
                      color="#DE3B3B"
                      style={{ cursor: 'pointer' }}
                      onClick={() => deleteUser(team.id)}
                    />
                  }
                </td>
              </tr>
            ))
            }
          </tbody>
        </Table>
      </Content>

    </Container>
  );
}
