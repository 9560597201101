import React, { FormEvent } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { Content, Container } from './styles';
import logonobg from '../../assets/logo_nobg.png'

import { signInRequest } from "../../store/modules/auth/actions";
import Button from '../../components/Button';
import history from '../../services/history';

const schema = Yup.object().shape({
  password: Yup.string().required('A senha é obrigatório'),
  email: Yup.string().required('O e-mail é obrigatório').email('Insira um email válido'),
})

export default function SignIn() {
  const dispatch = useDispatch();

  const [error, setError] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  async function handleSubmit(e: FormEvent): Promise<void> {
    e.preventDefault();
    setLoading(true);
    setError(null);

    await schema.validate({ email, password })
      .then(() => {
        dispatch(signInRequest(email, password));
        setLoading(false)
      })
      .catch(function (err) {
        console.log(err);
        if (err) {
          setError(err.message);
          setLoading(false);
          return;
        }
      });
  }

  return (
    <Container>
      <Content>
        <img src={logonobg} alt="Salas" style={{ maxWidth: '100%' }} />
      </Content>
      <Content>
        <form onSubmit={handleSubmit}>
          <h3>LOGIN</h3>
          <label htmlFor="email">SEU E-MAIL</label>
          <input
            name="email"
            type="email"
            placeholder="exemplo@email.com"
            maxLength={100}
            onChange={(e) => setEmail(e.target.value)}
          />

          <label htmlFor="email">SUA SENHA</label>
          <input
            name="password"
            type="password"
            placeholder="********"
            onChange={(e) => setPassword(e.target.value)}
          />

          {error && <span>{error}</span>}

          <Button typeButton="primary" title="Entrar" loading={loading} />
        </form>
        <button className="forgot_password" onClick={() => history.push('/reset/password')}>esqueci minha senha</button>
      </Content>
    </Container>
  );
}
