import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  align-self: center;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    font-size: 24px;
    color: #FFFFFF;
  }

  form input {
    margin-left: 10px;
    background: #FFF;
    border: 1px solid #EEE;
    border-radius: 4px;
    height: 36px;
    padding: 0 15px;
    color: #444444;
    border-radius: 4px;
  }
`;

export const Content = styled.div`
  margin-top: 15px;
  background: #FFF;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  color: #444444;


  tr + tr {
    border-top: 1px solid #EEE;
  }

  tr td button {
    background: transparent;
    border: none;

    tr td button + tr td button {
      margin-left: 20px;
    }
  }

  td {
    padding: 10px 0;
  }

`
