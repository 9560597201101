import React from 'react';

import { Wrapper } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}
