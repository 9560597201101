import React, { useState, useCallback, useEffect } from 'react';
import { MdDone } from 'react-icons/md';
import { Input } from 'reactstrap'
import { toast } from 'react-toastify';
import { Modal } from 'antd'
import { useSelector } from 'react-redux';
import moment from 'moment';
// import * as Yup from 'yup';

import { Container, Header, Content, WrapperInput, WrapperCheckbox } from './styles';
import Button from '../../../components/Button';
import api from '../../../services/api'
import history from '../../../services/history'
import '../../../../node_modules/antd/dist/antd.css';

// const schema = Yup.object().shape({
//   reason: Yup.string()
//     .required('Motivo é obrigatório'),
// });

export default function CreateRequest() {
  const user = useSelector(state => state.user.user)
  const church = useSelector(state => state.user.church)

  const [rooms, setRooms] = useState('')
  const [roomId, setRoomId] = useState('')
  const [reason, setReason] = useState('')
  const [quantity, setQuantity] = useState('')
  const [date, setDate] = useState('')
  const [hour, setHour] = useState('')
  const [hourEnd, setHourEnd] = useState('')
  const [isRecurring, setIsRecurring] = useState(false)

  const toggle = () => setIsRecurring(!isRecurring)

  const loadRooms = useCallback(async () => {
    try {
      const response = await api.get(`/room`);

      const data = response.data.map(room => ({
        ...room,
        value: room.id,
        label: room.name,
        church_id: room.church_id,
      }));

      const r = data.filter(room => room.church_id === church.id)
      console.log(r)
      setRooms(r)

      return r
    } catch (err) {
      return toast.error('Erro ao carregar os salas.');
    }
  }, [church.id]);

  useEffect(() => {
    loadRooms()
  }, [loadRooms])

  async function handleAddRequest(e) {
    e.preventDefault();

    const startAtFormatted = `${date} ${hour}`
    const endAtFormatted = `${date} ${hourEnd}`

    try {
      if (!roomId || !date || !hour || !hourEnd) {
        return Modal.error({
          title: 'Dados inválidos!',
          content: (
            <div>
              <p>Preencha todos os campos.</p>
            </div>
          )
        });
      }

      const response = await api.post('request', {
        user_id: user.id,
        room_id: roomId,
        reason,
        start_at: startAtFormatted,
        end_at: endAtFormatted,
        quantity,
        is_recurring: isRecurring
      })

      if (response.status === 200) {
        toast.success(`Reserva realizada com sucesso! Aguarde aprovação.`)
        history.replace('/');
      }
    } catch (err) {
      console.log(err)
      console.log(err.response?.data?.message)
      const msgError = err.response?.data?.message
      toast.error(msgError ? msgError : 'Falha ao realizar reserva. Tente novamente!')
    }
  }

  function onChangeTime(time) {
    setHour(time)
  };

  function onChangeTimeEnd(time) {
    setHourEnd(time)
  };

  function onChangeDate(date) {
    setDate(date)
  };

  return (
    <Container>
      <form onSubmit={handleAddRequest} >
        <Header>
          <strong>Solicitar reserva</strong>
          <Button typeButton="secundary" icon={<MdDone />} title="SALVAR" />
        </Header>

        <Content>
          <WrapperInput style={{ marginTop: 10 }}>
            <div className="col-xs-12 col-xs-12 col-md-12 col-lg-12">
              <strong>SALA</strong>
              <Input
                type="select"
                name="room_id"
                required
                onChange={e => {
                  setRoomId(Number(e.target?.value));
                }}>
                <option value="">Selecione a sala</option>
                {rooms && rooms.map(room => (
                  <option key={room.id} value={room.id}>{room.name}</option>
                ))}
              </Input>
            </div>
          </WrapperInput>

          <WrapperInput style={{ marginTop: 10 }}>
            <div className="col-xs-12 col-xs-12 col-md-8 col-lg-8">
              <strong>MOTIVO</strong>
              <Input
                name="reason"
                value={reason}
                onChange={(value) => setReason(value.target.value)}
                required
              />
            </div>

            <div className="col-xs-12 col-xs-12 col-md-4 col-lg-4">
              <strong>QUANTIDADE DE PESSOAS</strong>
              <Input
                type="number"
                name="quantity"
                value={quantity}
                onChange={(value) => setQuantity(value.target.value)}
                required
              />
            </div>
          </WrapperInput>
          <WrapperInput>
            <div className="col-xs-12 col-xs-12 col-md-12 col-lg-12">
              <strong>DIA</strong>
              <input
                type="date"
                name="data"
                id="data"
                min={moment().format('YYYY-MM-DD')}
                required
                placeholder="Selecione a data"
                onChange={e => onChangeDate(e?.target?.value)}
                value={date}
              />
            </div>
          </WrapperInput>

          <WrapperInput>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{ marginTop: 10 }}>
              <strong>INÍCIO</strong>
              <Input
                type="time"
                name="time"
                id="inicio"
                placeholder="Hora de início"
                required
                onChange={e => onChangeTime(e?.target?.value)}
                value={hour}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{ marginTop: 10 }}>
              <strong>FIM</strong>
              <Input
                type="time"
                name="time"
                id="fim"
                placeholder="Hora de término"
                required
                onChange={e => onChangeTimeEnd(e?.target?.value)}
                value={hourEnd}
              />
            </div>
          </WrapperInput>

          <WrapperCheckbox>
            <input
              type="checkbox"
              name="is_recurring"
              id="is_recurring"
              onClick={toggle}
              value={isRecurring}
            />
            <label htmlFor="is_recurring"><b>Sala Fixa</b></label>
          </WrapperCheckbox>

        </Content>
      </form>
    </Container>
  );
}
