import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { signInSuccess, signInFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(api.post, 'login', {
      email,
      password,
    });

    const { token, user, church, team } = response.data;
    console.log(token.token)

    api.defaults.headers.Authorization = `Bearer ${token.token}`;

    yield put(signInSuccess(token, user, church, team));

    history.push('/');
  } catch (err) {
    toast.error('Falha na autenticação, verifique seus dados.')
    yield put(signInFailure());
  }
}

export function signOut() {
  localStorage.removeItem('persist:room-manager')
  history.push('/login');
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
