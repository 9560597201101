import React, { useState } from 'react';
import { Modal, Result } from 'antd';
import { Form, Input } from 'unform';
import { MdKeyboardBackspace } from 'react-icons/md';
import * as Yup from 'yup';

import history from '../../services/history'
import api from '../../services/api'

import { Content, Wrapper } from './styles';

const schema = Yup.object().shape({
  email: Yup.string().email('Insira um email válido').required('O e-mail é obrigatório'),
})

function ResetPassword() {
  const [loading, setLoading] = useState(false);

  async function handleResetPassword({ email }) {
    try {
      setLoading(true)
      await api.post('/password/email', { email })

      return Modal.success({
        title: 'Senha redefinida com sucesso!',
        content: (
          <div>
            <p>Uma nova senha foi enviada para seu e-mail: {email}.</p>
          </div>
        ),
        onOk() {
          history.replace('/');
        },
      });
    } catch (error) {
      console.log('error ', error)
      return Modal.error({
        title: 'Erro ao redefinir senha!',
        content: (
          <div>
            <p>Ocorreu um erro ao redefinir sua senha, verifique seu e-mail e tente novamente.</p>
          </div>
        )
      });
    } finally {
      setLoading(false);
    }

  }

  return (
    <>
      <Wrapper>
        <Result
          status="404"
          title=""
          subTitle=""
          style={{ padding: 0 }}
        />
        <Content>
          <button
            type="button"
            style={{ background: 'transparent', border: 'none', outline: 0, display: 'flex', marginBottom: 24 }}
            onClick={(() => history.replace('/login'))}
          >
            <MdKeyboardBackspace size={36} />
          </button>
          <Form schema={schema} onSubmit={handleResetPassword}>
            <label htmlFor="email">E-mail</label>
            <Input name="email" type="email" placeholder="exemplo@email.com" />

            <button
              type="submit"
              disabled={loading}
            >
              {loading ? 'processando...' : 'Resetar senha'}
            </button>
          </Form>
        </Content>
      </Wrapper>
    </>
  )
}

export default ResetPassword;
