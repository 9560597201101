import styled from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  margin: 50px auto;
  background: #FFF;
  padding: 20px;
  border-radius: 4px;

  form {
    display: flex;
    flex-direction: column;

    h5 {
      margin-bottom: 20px;
    }

    input {
      background: #FFF;
      border: 1px solid #EEE;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 3px 0 10px;
      border-radius: 4px;
    }

    /* span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    } */

    hr {
      background: rgba(255, 255, 255, 0.5);
      margin: 10px 0 20px;
      height: 1px;
      border: 0;
    }

  }
    button {
      width: 100%;
      margin-top: 12px;
    }
`;
