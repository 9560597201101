import React, { useEffect, useState } from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Container, Header, Table, Content } from './styles';
import Button from '../../../components/Button';

import history from '../../../services/history';
import api from '../../../services/api';

export default function ListUsers() {
  const userLogger = useSelector(state => state.user.user)
  const churchUser = useSelector(state => state.user.church)
  const [users, setUsers] = useState([])

  useEffect(() => {
    async function loadUsers() {
      try {
        const response = await api.get(`user`);

        const userFilteredByChurch = response.data.filter(user => user.team.church_id === churchUser.id);

        if (response.status === 200) {
          setUsers(userFilteredByChurch)
        }
      } catch (err) {
        toast.error('Erro ao carregar usuários.')
        console.log(err)
      }
    }

    loadUsers()
  }, [churchUser.id])

  async function deleteUser(id) {
    try {
      await api.delete(`user/${id}`)

      toast.success('Usuário excluído com sucesso.')

      setUsers(users.filter(r => r.id !== id));
    } catch (err) {
      toast.error('Erro ao excluir usuário.')
      console.log(err)
    }
  }

  return (
    <Container>
      <Header>
        <strong>Usuários</strong>
        <div>
          <Button
            typeButton="secundary"
            icon={<MdAdd />}
            title="ADICIONAR"
            onClick={() => history.push('/manager/users/create')}
          />
        </div>
      </Header>

      <Content>
        <Table>
          <thead>
            <tr>
              <th>NOME</th>
              <th>E-MAIL</th>
              <th>TIPO</th>
              <th style={{ textAlign: 'center' }}>#</th>
            </tr>
          </thead>
          <tbody>
            {users && users.map(user => (
              <tr key={user.id}>
                <td> {user.name} </td>
                <td> {user.email} </td>
                <td> {user && user.role.name} </td>
                <td style={{ textAlign: 'center' }}>
                  {user && user.id !== userLogger.id &&
                    <MdDelete
                      size={16}
                      color="#DE3B3B"
                      style={{ cursor: 'pointer' }}
                      onClick={() => deleteUser(user.id)}
                    />
                  }
                </td>
              </tr>
            ))
            }
          </tbody>
        </Table>
      </Content>

    </Container>
  );
}
