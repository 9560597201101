import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';

import { Container, Header, Table, Content } from './styles';
import Button from '../../../components/Button';
import history from '../../../services/history';
import api from '../../../services/api';

export default function ListRooms() {
  const [rooms, setRooms] = useState([])

  useEffect(() => {
    async function loadRooms() {
      try {
        const response = await api.get(`room`)

        if (response.status === 200) {
          setRooms(response.data)
        }
        console.log(response.data)
      } catch (err) {
        console.log(err)
      }
    }

    loadRooms()
  }, [])

  async function deleteRoom(id) {
    try {
      await api.delete(`room/${id}`)

      toast.success('Sala excluída com sucesso.')

      setRooms(rooms.filter(r => r.id !== id));
    } catch (err) {
      toast.error('Erro ao excluir sala.')
      console.log(err)
    }
  }

  return (
    <Container>
      <Header>
        <strong>Salas</strong>
        <div>
          <Button
            icon={<MdAdd />}
            title="ADICIONAR"
            onClick={() => history.push('/manager/rooms/create')}
            typeButton="secundary"
          />
        </div>
      </Header>

      <Content>
        <Table>
          <thead>
            <tr>
              <th>SALA</th>
              <th>DESCRIÇÃO</th>
              <th>IGREJA</th>
              <th style={{textAlign: 'center'}}>#</th>
            </tr>
          </thead>
          <tbody>
            {rooms && rooms.map(room => (
              <tr key={room.id}>
                <td> {room.name} </td>
                <td> {room.description} </td>
                <td> {room?.church_id === 1 ? 'SEDE' : 'CEILÂNDIA'} </td>
                <td style={{textAlign: 'center'}}>
                  { room &&
                    <MdDelete
                      size={16}
                      color="#DE3B3B"
                      style={{cursor: 'pointer'}}
                      onClick={() => deleteRoom(room.id)}
                    />
                  }
                </td>
              </tr>
            ))
            }
          </tbody>
        </Table>
      </Content>

    </Container>
  );
}
