export function getAllChurchs() {
  return {
    type: '@church/GET_ALL_CHURCHS',
  };
}

export function getChurchSuccess(churchs) {
  return {
    type: '@church/GET_CHURCH_SUCCESS',
    payload: { churchs }
  };
}
