import styled from 'styled-components';
import { dark } from '../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  align-self: center;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    font-size: 24px;
    color: #FFFFFF;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    display: flex;
    /* flex: 1; */
    flex-direction: column;

    input {
      background: #FFF;
      border: 1px solid #EEE;
      height: 44px;
      color: ${dark};
      padding: 10px;
      margin: 3px 0 10px;
      border-radius: 4px;
      font-size: 14px;
    }
    select {
      background: #FFF;
      border: 1px solid #EEE;
      height: 44px;
      color: ${dark};
      padding: 10px;
      margin: 3px 0 10px;
      border-radius: 4px;
      font-size: 14px;
    }
    span {
      input {
        height: 44px!important;
      }
    }
    div {
      width: 100%;
    }

  }
`;

export const Content = styled.div`
  background: #FFF;
  padding: 15px 0;
  margin-top: 15px;
  border-radius: 4px;



  span {
    color: #333;
    font-weight: bold;
  }

  input {
    font-size: 14px;
  }
`;

export const WrapperCheckbox = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  margin-top: 12px;

  input {
    margin-right: 6px;
  }

  label {
    margin: 0;
  }
`;
