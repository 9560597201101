import produce from 'immer';

const INITIAL_STATE = {
  churchs: [],
};

export default function church(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@church/GET_CHURCH_SUCCESS':
      return produce(state, draft => {
        console.log(action.payload)
        draft.churchs = action.payload.churchs;
      })
    default:
      return state;
  }

}
