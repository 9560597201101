import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route'

import SignIn from '../pages/SignIn'

import Profile from '../pages/Profile'

import CreateRequest from '../pages/Requests/CreateRequest';
import MyRequests from '../pages/Requests/MyRequests';
import TodayRequests from '../pages/Requests/TodayRequests';
import Rooms from '../pages/Rooms';

import CreateRooms from '../pages/ManagerRooms/CreateRooms';
import ListRooms from '../pages/ManagerRooms/ListRooms';

import CreateUsers from '../pages/ManagerUsers/CreateUsers';
import ListUsers from '../pages/ManagerUsers/ListUsers';
import ManagerRequests from '../pages/ManagerRequests';

import ResetPassword from '../pages/ResetPassword';

import CreateTeam from '../pages/ManagerTeams/CreateTeam';
import ListTeams from '../pages/ManagerTeams/ListTeams';

export default function Routes() {
  return (
    <Switch>

      <Route path="/" exact isPrivate component={Rooms} />

      <Route path="/login" component={SignIn} />

      <Route path="/profile" isPrivate component={Profile} />

      <Route path="/reset/password" component={ResetPassword} />

      <Route path="/request/today" isPrivate component={TodayRequests} />
      <Route path="/request/create" isPrivate component={CreateRequest} />
      <Route path="/requests" isPrivate component={MyRequests} />

      {/* SUPER USER */}
      <Route path="/manager/users/create" isPrivate component={CreateUsers} />
      <Route path="/manager/users" isPrivate component={ListUsers} />

      <Route path="/manager/rooms/create" isPrivate component={CreateRooms} />
      <Route path="/manager/rooms" isPrivate component={ListRooms} />

      <Route path="/manager/requests" isPrivate component={ManagerRequests} />

      <Route path="/manager/teams/create" isPrivate component={CreateTeam} />
      <Route path="/manager/teams" isPrivate component={ListTeams} />

      {/* 404 */}
      {/* <Route path="*"  component={Rooms} /> */}

    </Switch>
  );
}
