import React from 'react';

import { Container } from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  title?: string;
  loading?: boolean;
  typeButton: 'primary' | 'secundary'
}

export default function Button(props: ButtonProps) {
  const { icon, loading, typeButton, title, ...rest } = props;
  return (
    <Container {...rest} typeButton={typeButton}>
      <div>
        {icon ?? null}
        {loading
          ? <span>carregando...</span>
          : <span>{title}</span>
        }
      </div>
    </Container>
  );
}
