import styled from 'styled-components'
import { darken } from 'polished'
import { primary } from '../../styles/colors';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  div:nth-child(1) {
    width: 40%;
  }
  div:nth-child(2) {
    background: white;
    width: 60%;
  }

  @media (max-width: 960px) {
    flex-direction: column;

    div:nth-child(1) {
      max-height: 200px;
    }
    div:nth-child(2) {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    min-width: 280px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 60%;

    h3 {
      color: #444444;
      font-weight: bold;
      margin-bottom: 30px;
    }

    label {
      margin-top: 10px;
      text-align: left;
      font-weight: bold;
      color: #444444;
    }

    input {
      background: #FFF;
      border: 1px solid #EEE;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 3px 0 10px;

      &::placeholder {
        color: #999999;
      }
    }

    span {
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
      color: ${primary};
    }

    button {
      margin: 5px 0 0;
      height: 44px;
      border: 0;
      border-radius: 4px;
      color: #FFF;
      font-size: 16px;
      font-weight: bold;
      transition: background 0.2s;

      &:hover {
        background: ${darken('0.04', primary)}
      }
    }
  }

  @media (max-width: 460px) {
    form {
      width: 80%;
    }
  }
  .forgot_password {
    background: transparent;
    border: none;
    margin-top: 16px;
    outline: 0
  }
`
