import React from 'react';
import { Container } from 'reactstrap';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

import { Wrapper } from './styles';

export default function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <Header />
      <Container>
        {children}
      </Container>
      <Footer />
    </Wrapper>
  );
}
