import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;
  align-self: center;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    font-size: 24px;
    color: #FFFFFF;
  }

  div {
    display: flex;
  }

  form input {
    margin-left: 10px;
    background: #FFF;
    border: 1px solid #EEE;
    border-radius: 4px;
    height: 36px;
    padding: 0 15px;
    color: #444444;
    border-radius: 4px;
  }
`;

export const Content = styled.div`
  margin-top: 15px;
  background: #FFF;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);



   /* $screen-sm-min: 768px;
$shadow: 0 2px 3px rgba(0, 0, 0, .2);
$gray-light: rgba(0, 0, 0, .35);
$faded-yellow: lighten(#f1c40f, 40%); */

@media (max-width: 768px) {
table {
  table-layout: fixed;
  background: transparent;
  margin-bottom: 0;
  width: 100%;
  thead {
    display: none;
  }
  tbody tr {
    box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
    background: #fff;
    border-bottom: 1px solid #ddd;
    /* cursor: pointer; */
    display: block;
    padding: 15px 10px;
    margin: 0 0 10px 0;
    min-width: ${props => ((props.size.width)/2) + ((props.size.width)/3)}px;
  }
  tbody td {
    border: 0;
    display: block;
    padding: 10px 10px 20px 40%;
    position: relative;
    &:first-of-type::after {
      visibility: hidden;
    }
    &:after {
      content: '';
      width: calc(100% - 30px);
      display: block;
      margin: 0 auto;
      height: 1px;
      background: #ddd;
      position: absolute;
      left: 0;
      right: 0;
      top: -6px
    }
    &:before {
      color: #ddd;
      text-transform: uppercase;
      font-size: .85em;
      content: attr(data-title);
      display: table-cell;
      font-weight: 500;
      height: 100%;
      left: 15px;
      margin: auto;
      position: absolute;
      vertical-align: middle;
      white-space: nowrap;
      width: 40%;
    }
  }
  thead th {
    text-transform: uppercase;
    font-size: .85em;
    color: #ddd;
    letter-spacing: .5pt;
  }
  }
}
`;
