import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';
import { Result } from 'antd';

import { Container, Header, Table, Content } from './styles';
import Button from '../../../components/Button';
import history from '../../../services/history';
import api from '../../../services/api';
import { useSelector } from 'react-redux';
import { darkgray, green, red } from '../../../styles/colors';

export default function MyRequests() {
  let [requests, setRequests] = useState([])
  const user = useSelector(state => state.user.user)
  const churchUserId = useSelector(state => state.user?.church?.id)

  console.log('teste')

  useEffect(() => {
    async function loadMyRequests() {
      try {
        const response = await api.get(`/request/${churchUserId}`)

        if (response.status === 200) {
          const myRequests = response.data.filter(r => r.user_id === user.id)
          const data = myRequests.map(r => ({
            ...r,
            date_start: moment(r.start_at).format("ddd - DD/MM, HH:mm"),
          }))
          // console.log('requewste ', data)
          setRequests(data)
        }
      } catch (err) {
        console.log(err)
      }
    }

    loadMyRequests()
  }, [churchUserId, user.id])

  async function deleteRequest(id) {
    try {
      await api.delete(`request/${id}`)

      toast.success('Reserva excluída com sucesso.')

      setRequests(requests.filter(r => r.id !== id));
    } catch (err) {
      toast.error('Erro ao excluir reserva.')
      console.log(err)
    }
  }

  return (
    <Container>
      <Header>
        <strong>Minhas Reservas</strong>
        <div>
          <Button icon={<MdAdd />} title="ADICIONAR" onClick={() => history.push('request/create')} typeButton="secundary" />
        </div>
      </Header>

      <Content>
        <Table>
          <thead>
            <tr>
              <th>SALA</th>
              <th>DIA</th>
              <th>STATUS</th>
              <th style={{ textAlign: 'center' }}>#</th>
            </tr>
          </thead>
          {requests?.length > 0 && requests.map(request => (
            <tbody key={request.id}>
              <tr>
                <td> {request.room && request.room.name} </td>
                <td> {request.date_start} </td>
                <td>
                  {request && request.status === 0 && <span style={{ color: darkgray }}>pendente</span>}
                  {request && request.status === 1 && <span style={{ color: green }}>aceito</span>}
                  {request && request.status === 2 && <span style={{ color: red }}>recusado</span>}
                </td>
                <td style={{ textAlign: 'center' }}>
                  <MdDelete
                    size={16}
                    color={red}
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteRequest(request.id)}
                  />
                </td>
              </tr>
            </tbody>
          ))
          }
        </Table>
        {requests?.length <= 0 && (
          <Result
            title="Sem reservas cadastradas"
          />
        )}
      </Content>

    </Container>
  );
}
