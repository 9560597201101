import axios from 'axios'

const url = "https://api-salas.saratech.com.br/"
// const url = "http://54.233.154.251:5000"
// const url = "http://localhost:3333"

const api = axios.create({
  baseURL: url
})

export default api;
