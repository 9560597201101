export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password }
  };
}

export function signInSuccess(token, user, church, team) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, church, team }
  };
}

export function signInFailure() {
  return {
    type: '@auth/SIGN_IN_FAILURE'
  };
}

export function signOutRequest() {
  return {
    type: '@auth/SIGN_OUT'
  };
}
