import React from 'react';

const Footer = () => {
  return (
    <footer className="page-footer font-small">
      <div className="footer-copyright text-center py-3">
        <span style={{ color: '#DDD' }}>© 2024 powered by</span><br />
        <span style={{ color: '#DDD' }}>Arena Tech Team</span>
      </div>
    </footer>
  )
}

export default Footer;
