import produce from 'immer';

const INITIAL_STATE = {
  user: null,
  church: null,
  team: null,
};

interface UserState {
  user: object | null;
  church: object | null;
  team: object | null;
}

export default function user(state: UserState = INITIAL_STATE, action: { type: any; payload: { user: null; church: null; team: null; }; }) {
  switch (action.type) {
    case '@auth/SIGN_IN_SUCCESS':
      return produce(state, draft => {
        console.log(action.payload)
        draft.user = action.payload.user;
        draft.church = action.payload.church;
        draft.team = action.payload.team;
      })
    case '@auth/SIGN_OUT':
      return produce(state, draft => {
        draft.user = null;
      })
    default:
      return state;
  }

}
