import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import api from '../../../services/api';
import { Container, Header, Table, Content } from './styles';
import { darkgray, green, red } from '../../../styles/colors';
import { Result } from 'antd';
import { useSelector } from 'react-redux';

export default function TodayRequests() {
  const [requests, setRequests] = useState([])

  const churchUser = useSelector(state => state.user?.church)

  useEffect(() => {
    async function loadMyRequests() {
      try {
        const response = await api.get(`/request/today`)

        if (response.status === 200) {
          const hoje = moment().format('DD/MM/YYYY')

          const data = response.data.map(request => ({
            ...request,
            day: moment(request.start_at).format("dddd"),
            start: moment(request.start_at).format("HH:mm"),
            end: moment(request.end_at).format("HH:mm"),
            today: moment(request.start_at).format('DD/MM/YYYY'),
            name: request.user?.name,
            team: request.user?.team.name,
          }))

          const todayRequests = data.filter(req => req.today === hoje && req.church_id === churchUser.id && req.status === 1)

          setRequests(todayRequests)
        }
      } catch (err) {
        toast.error('Não foi possível listar as reservas, tente novamente!')
        console.log(err)
      }
    }

    loadMyRequests()
  }, [churchUser.id])

  return (
    <Container>
      <Header>
        <strong>Reservas de Hoje</strong>
      </Header>

      <Content>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>SALA</th>
              <th>MOTIVO</th>
              <th>DIA</th>
              <th>HORÁRIO</th>
              <th>RESPONSÁVEL</th>
              <th>EQUIPE</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {requests && requests.map(request => (
              <tr key={request.id}>
                <td> {request?.id} </td>
                <td> {request?.room && request.room?.name} </td>
                <td> {request?.reason} </td>
                <td> {request?.day} </td>
                <td> {request?.start + ' - ' + request?.end} </td>
                <td> {request?.name} </td>
                <td> {request?.team} </td>
                <td>
                  {request && request.status === 0 && <span style={{ color: darkgray }}>pendente</span>}
                  {request && request.status === 1 && <span style={{ color: green }}>aceito</span>}
                  {request && request.status === 2 && <span style={{ color: red }}>recusado</span>}
                </td>
              </tr>
            ))
            }
          </tbody>
        </Table>
        {requests?.length <= 0 && (
          <Result
            title="Sem reservas para hoje"
          />
        )}
      </Content>

    </Container>
  );
}
