import styled from 'styled-components'
import { darken } from 'polished'
import { primary } from '../../styles/colors';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  padding: 20px;
  width: 100%;
  max-width: 400px;
  background: #FFF;
  text-align: center;
  box-shadow: 0 0 10px rgba(0,0,0, 0.2);
  border-radius: 4px;

  form {
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */

    label {
      margin-top: 10px;
      text-align: left;
      font-weight: bold;
      color: #444444;
    }

    input {
      background: #FFF;
      border: 1px solid #EEE;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #333;
      margin: 3px 0 10px;

      &::placeholder {
        color: #999999;
      }
    }

    span {
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
      color: ${primary};
    }

    button {
      background: ${primary};
      margin: 5px 0 0;
      height: 44px;
      border: 0;
      border-radius: 4px;
      color: #FFF;
      font-size: 16px;
      font-weight: bold;
      transition: background 0.2s;

      &:hover {
        background: ${darken('0.04', primary)}
      }
    }
  }

  .forgot_password {
    background: transparent;
    border: none;
    margin-top: 16px;
    outline: 0
  }

`;
