import React, { useState } from 'react';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import history from '../../services/history'

import { Container } from './styles';
// import logo from '../../assets/logo-header.svg';
import { signOutRequest } from '../../store/modules/auth/actions';

export default function Header({ children }) {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector(state => state.user.user)

  const toggle = () => setIsOpen(!isOpen);

  function logout() {
    dispatch(signOutRequest())
  }

  return (
    <Container>
      <div className="py-2" style={{ backgroundColor: '#18151E', borderBottom: '1px solid #99A9F9' }}>
        <Navbar style={{ background: '#18151E' }} light expand="md">
          <NavbarToggler onClick={toggle} style={{ background: '#fff' }} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar style={{ fontWeight: 'bold' }}>
              <NavItem className="px-1">
                <NavLink style={{ color: '#fff' }} href="/">HOME</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar className="px-1">
                <DropdownToggle nav caret style={{ color: '#fff' }}>
                  RESERVAS
                </DropdownToggle>
                <DropdownMenu>
                  <Link className="dropdown-item" to="/request/today">
                    RESERVAS DE HOJE
                  </Link>
                  <Link className="dropdown-item" to="/request/create" >
                    NOVA RESERVA
                  </Link>
                  <Link className="dropdown-item" to="/requests">
                    MINHAS RESERVAS
                  </Link>
                </DropdownMenu>

              </UncontrolledDropdown>

              {user && user.role_id === 1 &&
                <UncontrolledDropdown nav inNavbar className="px-1">
                  <DropdownToggle nav caret style={{ color: '#fff' }}>
                    GERENCIADOR
                  </DropdownToggle>
                  <DropdownMenu>
                    {user && user.role_id === 1 &&
                      <>
                        <Link className="dropdown-item" to="/manager/users">
                          USUÁRIOS
                        </Link>
                        {/* <DropdownItem href="/manager/teams">
                        EQUIPES
                      </DropdownItem> */}
                      </>
                    }
                    <Link className="dropdown-item" to="/manager/rooms">
                      SALAS
                    </Link>
                    <Link className="dropdown-item" to="/manager/requests">
                      RESERVAS
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
              {user && user.role_id === 2 &&
                <UncontrolledDropdown nav inNavbar className="px-1">
                  <DropdownToggle nav caret style={{ color: '#fff' }}>
                    GERENCIADOR
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link className="dropdown-item" to="/manager/requests">
                      RESERVAS
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            </Nav>

            {/* SE TIVER USUÁRIO LOGADO  - MOSTRAR NOME E LABEL PERFIL */}
            {user &&
              <UncontrolledDropdown className="px-1">
                <DropdownToggle nav caret style={{ color: '#fff' }}>
                  {user.name}
                </DropdownToggle>
                <DropdownMenu right>
                  <Link className="dropdown-item" to="/profile">
                    MEU PERFIL
                  </Link>
                  <DropdownItem onClick={logout}>
                    SAIR
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            }
            {/* SE NAO - MOSTRAR OPÇÃO LOGINL */}

            {!user &&
              <button style={{
                background: 'transparent',
                border: 0,
                fontSize: 14,
                color: '#EEE'
              }}
                onClick={() => { history.push('/login') }}
              >Login</button>
            }

          </Collapse>
        </Navbar>
      </div>
    </Container>
  );
}
