import { createGlobalStyle } from 'styled-components'

import 'react-toastify/dist/ReactToastify.css'

export default createGlobalStyle`

/* @import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,300i,400,400i,600,600i,700,700i&display=swap'); */

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

html, body, #root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

body, input, button {
  font: 14px 'Titillium Web', sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

input:disabled {
  background: #EEEEEE!important;
  border: 1px solid #CCCCCC!important;
  cursor:  not-allowed!important;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 14px!important;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 14px!important;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 14px!important;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 14px!important;
}
.ant-select-selection__placeholder {
  font-size: 14px!important;
}
.ant-time-picker-large .ant-time-picker-input {
  font-size: 14px!important;
}


`;
