import React, { useEffect, useState } from 'react';
// import { toast } from 'react-toastify';
import { Calendar, Badge, Select, Row, Col, Radio, Modal } from 'antd'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import { Container, Header, Content } from './styles';
// import history from '../../services/history';
import api from '../../services/api'
import { config } from '../../helpers/configCalendar';

// CONFIG MOMENT LOCALE BRAZIL
import moment from 'moment'
import 'moment/locale/pt-br';
import { useSelector } from 'react-redux';
moment.locale('pt-br');

const { Group, Button } = Radio;

export default function Rooms() {
  const [request, setRequest] = useState([])
  const [value, setValue] = useState(moment())

  const churchUser = useSelector(state => state.user?.church)

  useEffect(() => {
    async function loadRequest() {
      try {
        const response = await api.get(`/request/${churchUser.id}`)
        const data = response.data.filter(r => r.room.church.id === churchUser.id)
        // const data = response.data.filter(r => r.room.church.id === churchUser?.id)
        if (response.status === 200) {
          setRequest(data)
        }

      } catch (err) {
        console.log(err)

      }
    }
    loadRequest()
  }, [churchUser.id])


  function onSelect(value) {
    setValue(value)

    // FORMATA RESERVAS
    const requestMap = request.map(r => ({
      id: r.id,
      user: r.user?.name,
      team: r.user?.team?.name,
      room: r.room.name,
      status: r.status,
      date: moment(r.start_at).format('YYYY-MM-DD'),
      start: moment(r.start_at).format('HH:mm'),
      end: moment(r.end_at).format('HH:mm'),
      reason: r.reason,
    })).sort(function (a, b) {
      if (a.room > b.room) {
        return 1;
      }
      if (a.room < b.room) {
        return -1;
      }
      // a must be equal to b
      return 0;
    })

    // FILTRA RESERVAS
    const requestFilter = requestMap.filter(r => r.date === value.format('YYYY-MM-DD'))

    Modal.info({
      title: `Solicitações de reservas - ${value.format('DD/MM/YYYY')}`,
      content: (
        <List>
          {requestFilter && requestFilter.map(r => (
            <ListItem key={r.id}>
              <ListItemIcon>
                {requestIconStatus(r.status)}
              </ListItemIcon>
              <ListItemText
                primary={r.room}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      //className={classes.inline}
                      color="textPrimary"
                    >{r.team} - </Typography>  {r.start} - {r.end}
                    <br />
                    {r.reason}
                  </React.Fragment>
                }
              />
              {/* <Divider variant="inset" component="li" /> */}
            </ListItem>
          ))}
        </List>
      ),
    });

  };

  function onPanelChange(value) {
    console.log(value)
  };

  function getListData(value) {
    let listData;
    request.forEach(r => {
      switch (value.format('DD/MM/YYYY')) {
        case moment(r.start_at).format('DD/MM/YYYY'):
          listData = [
            { type: requestStatus(r.status), content: r.reason },
          ];
          break;
        default:
      }
    });
    return listData || [];
  }

  function requestIconStatus(value) {
    switch (value) {
      case 0: return <ErrorIcon style={{ color: '#ffc107' }}> </ErrorIcon>
      case 1: return <CheckCircleIcon style={{ color: '#28a745' }}></CheckCircleIcon>
      case 2: return <CancelIcon style={{ color: '#dc3545' }}></CancelIcon>
      default: return <ErrorIcon style={{ color: '#ffc107' }}> </ErrorIcon>
    }
  }

  function requestStatus(value) {
    switch (value) {
      case 0: return 'warning'
      case 1: return 'success'
      case 2: return 'error'
      default: return 'warning'
    }
  }

  function dateCellRender(value) {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  }

  function getMonthData(value) {
    console.log(value)
    if (value.month() === 8) {
      return 1394;
    }
  }

  function monthCellRender(value) {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  }

  return (
    <Container>
      <Header>
        <strong>Reservas</strong>
      </Header>

      <Content>
        <Calendar
          value={value}
          onSelect={onSelect}
          onPanelChange={onPanelChange}
          locale={config}
          dateCellRender={dateCellRender}
          monthCellRender={monthCellRender}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current.month(i);
              months.push(localeData.monthsShort(current));
            }

            for (let i = start; i < end; i++) {
              monthOptions.push(
                <Select.Option className="month-item" key={`${i}`}>
                  {months[i]}
                </Select.Option>,
              );
            }
            const month = value.month();

            const year = value.year();
            const options = [];
            for (let i = year; i < year + 2; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>,
              );
            }
            return (
              <div style={{ padding: 10 }}>
                <div style={{ marginBottom: '10px' }}>Sara Nossa Terra </div>
                <Row type="flex" justify="center">
                  <Col>
                    <Group onChange={e => onTypeChange(e.target.value)} value={type}>
                      <Button value="month">Mês</Button>
                      {/* <Button value="year">Ano</Button> */}
                    </Group>
                  </Col>
                  <Col style={{ margin: '0 10px' }}>
                    <Select
                      dropdownMatchSelectWidth={false}
                      value={String(month)}
                      onChange={selectedMonth => {
                        const newValue = value.clone();
                        newValue.month(parseInt(selectedMonth, 10));
                        onChange(newValue);
                        setValue(newValue)
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      onChange={newYear => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                      value={String(year)}
                    >
                      {options}
                    </Select>
                  </Col>

                </Row>
              </div>
            );
          }}
        />

      </Content>
    </Container>
  );
}
